import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import Home from "./routes/Home";
import Login from "./routes/Login";
import Nav from "./components/Nav";
import Bids from "./routes/Bids";
import Users from "./routes/Users";
import User from "./routes/User";
import ViewBid from "./routes/ViewBid";
import NewContract from "./routes/NewContract";
import ViewContract from "./routes/ViewContract";
import AdminRoute from "./components/AdminRoute";
import AdminContracts from "./routes/AdminContracts";
import AdminViewContract from "./routes/AdminViewContract";
import NotificationList from "./components/NotificationList";
import Auth from "./routes/Auth";

function App() {
  return (
    <BrowserRouter>
      <Nav />
      <Container>
        <main>
          <NotificationList />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/auth" element={<Auth />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/bids" element={<Bids />}></Route>
            <Route path="/bids/:id" element={<ViewBid />}></Route>
            <Route
              path="/contracts/:id"
              element={<ViewContract />}
            ></Route>
            <Route path="/admin/users" element={
              <AdminRoute>
                <Users/>
              </AdminRoute>
            }></Route>
            <Route path="/admin/users/:user" element={
              <AdminRoute>
                <User />
              </AdminRoute>
            }></Route>
            <Route path="/admin/contracts" element={
              <AdminRoute>
                <AdminContracts />
              </AdminRoute>
            }></Route>
            <Route path="/admin/contracts/new" element={
              <AdminRoute>
                <NewContract/>
              </AdminRoute>
            }></Route>
            <Route path="/admin/contracts/:id" element={
              <AdminRoute>
                <AdminViewContract/>
              </AdminRoute>
            }></Route>
          </Routes>
        </main>
      </Container>
    </BrowserRouter>
  );
}

export default App;
