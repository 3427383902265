import { useState, useEffect } from "react";
import { Row, Col, ButtonGroup, Button, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Contract from "../components/Contract";
import { BidTableActionProps, Contract as ContractType, ContractInput, EditableItemStack } from "../types";
import AdminEditContractItems from "../components/AdminEditContractItems";
import BidsTable from "../components/BidsTable";
import ContractForm from "../components/ContractForm";
import { doFetch, isContractPastBidDeadline } from "../utils";
import { addNotification } from "../redux/flash";
import { useAppDispatch } from "../redux/hooks";


const AdminBidActions = ({bid}: BidTableActionProps) => {
  const setBidStatus = (status) => {
    doFetch(`/api/admin/contracts/${bid.contract.id}/bids/${bid.id}/status`, {
      method: 'POST',
      body: JSON.stringify({
        status: status
      })
    }).then(resp => {
      if (!resp.ok) {
        throw Error('invalid response')
      }

      document.location.reload()
    }).catch(err => {
      console.error(err)
    })
  }

  const actions = []

  switch(bid.status) {
    case 'placed':
      actions.push(<Button key={actions.length} variant="success" onClick={() => setBidStatus("accepted")}>Accept</Button>)
      actions.push(<Button key={actions.length} variant="warning" onClick={() => setBidStatus("rejected")}>Reject</Button>)
      break
    case 'accepted':
      actions.push(<Button key={actions.length} variant="success" onClick={() => setBidStatus("completed")}>Mark Completed</Button>)
      actions.push(<Button key={actions.length} variant="danger" onClick={() => setBidStatus("defaulted")}>Mark Defaulted</Button>)
      actions.push(<Button key={actions.length} variant="warning" onClick={() => setBidStatus("rejected")}>Reject</Button>)
      break
    case 'rejected':
      actions.push(<Button key={actions.length} variant="success" onClick={() => setBidStatus("accepted")}>Accept</Button>)
      break
    case 'defaulted':
      actions.push(<Button key={actions.length} variant="success" onClick={() => setBidStatus("completed")}>Mark Completed</Button>)
      break
    case 'completed':
      actions.push(<Button key={actions.length} variant="success" onClick={() => setBidStatus("defaulted")}>Mark Defaulted</Button>)
      break
  }

  return (
    <ButtonGroup>
      {actions}
    </ButtonGroup>
  )
}

export default function AdminViewContract() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [contract, setContract] = useState<ContractType>(null);
  const [editingItems, setEditingItems] = useState<boolean>(false);
  const [showEditForm, setShowEditForm] = useState<boolean>(false);

  const onEditItemsClose = (save: boolean, items: EditableItemStack[]) => {
    setEditingItems(false)
    if (save) {
      saveItems(items)
    }
  }

  useEffect(() => {
    doFetch(`/api/admin/contracts/${id}`)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }
        throw new Error('unable to fetch data')
      })
      .then((data: ContractType) => {
        if (data.bids) {
          for (let i = 0; i < data.bids.length; i++) {
            data.bids[i].contract = data
          }
        }

        setContract(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [id]);

  const saveItems = (items: EditableItemStack[]) => {
    if (items.length === 0) {
      return
    }

    doFetch(`/api/contracts/${id}/items`, {
      method: "POST",
      body: JSON.stringify({
        "items": items,
      })
    })
    .then((resp) => {
      if (resp.ok) {
        return resp.json();
      }

      throw new Error('unable to set data')
    })
    .then((data) => {
      setContract(data);
    })
    .catch((e) => {
      console.error(e);
    });
  }


  if (!contract) {
    return <h1>Loading...</h1>;
  }

  const deleteContract = () => {
    if (!window.confirm("Are you sure? This will delete everything related to this contract.")) {
      return
    }

    doFetch(`/api/admin/contracts/${contract.id}`, {
      method: "DELETE",
    }).then((resp) => {
      if (!resp.ok) {
        throw new Error('invalid response')
      }

      navigate('/admin/contracts')
    }).catch(err => {
      console.error(err)
    })
  }

  const onFormClose = () => {
    setShowEditForm(false)
  }

  const onFormSubmit = (input: ContractInput) => {
    doFetch(`/api/admin/contracts/${contract.id}`, {
      method: 'PATCH',
      body: JSON.stringify(input)
    }).then((resp) => {
      if (!resp.ok) {
        throw new Error('invalid response')
      }

      document.location.reload()
    }).catch(err => {
      console.error(err)
    })

    onFormClose()
  }

  const bulkReject = () => {
    if (!window.confirm("Are you sure?")) {
      return
    }

    doFetch(`/api/admin/contracts/${contract.id}/bulk-reject`, {
      method: 'POST',
    }).then((resp) => {
      if (!resp.ok) {
        dispatch(addNotification({
          type: "danger",
          text: "Unable to perform bulk action",
          dismissTime: 10,
        }))
      }
    }).catch(err => {
      console.error(err)
    })
  }

  return (
    <>
      <Row>
        <h1>Contract</h1>
      </Row>
      <Row>
        <Col md={8}>
            <Contract contract={contract} showActions={false} pullFulfillments />
        </Col>
        <Col md={4}>
            <h2>Actions</h2>
            <ButtonGroup vertical>
              <Button variant="warning" onClick={() => setShowEditForm(true)}>Edit Contract</Button>
              <Button variant="info" disabled={isContractPastBidDeadline(contract)} onClick={() => setEditingItems(true)}>Edit Items</Button>
              <Button variant="danger" onClick={() => deleteContract()}>Delete</Button>
            </ButtonGroup>
            <h2>Bulk Actions</h2>
            <ButtonGroup vertical>
              <Button variant="warning" onClick={() => bulkReject()}>Mark Unaccepted as Rejected</Button>
            </ButtonGroup>

            <AdminEditContractItems contract={contract} show={editingItems} onClose={onEditItemsClose} />
            <Modal show={showEditForm} onHide={() => onFormClose()} backdrop="static" size="xl">
              <Modal.Header closeButton>
                <Modal.Title>Edit Contract</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <ContractForm contract={contract} onSubmit={onFormSubmit} />
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={onFormClose}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>Bids</h2>
          <BidsTable showUser bids={contract.bids} actionComponent={AdminBidActions} />
        </Col>
      </Row>
    </>
  );
}
