import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { User } from "../types";
import { getRoleForUser } from "../utils";

interface Props {
  users: User[]
}

export default function UsersTable({users}: Props) {
  const rows = users.map((u, i) => {
    return (
      <tr key={i}>
        <td>{u.username}</td>
        <td>{getRoleForUser(u)}</td>
        <td>
          <Link to={`/admin/users/${u.id}`} className="btn btn-info">
            View
          </Link>
        </td>
      </tr>
    );
  });

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <td>Name</td>
          <td>Role</td>
          <td></td>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  )
}