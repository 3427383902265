import { useEffect, useState } from "react";
import { Col, Row, Image, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import BidStatus from "../components/BidStatus";
import { Bid } from "../types";
import { doFetch } from "../utils";

export default function ViewBid() {
  const { id } = useParams();
  const [bid, setBid] = useState<Bid>(undefined);

  useEffect(() => {
    doFetch(`/api/bids/${id}`)
    .then((resp) => {
      if (!resp.ok) {
        throw new Error('invalid response')
      }

      return resp.json()
    }).then(data => {
      setBid(data.bid)
    }).catch(err => {
      console.error(err)
    })
  }, [id])

  if (!bid) {
    return <h1>Loading...</h1>
  }

  let totalCost = 0
  const rows = bid.item_stacks.map((item, idx) => {
    const itemStack = bid.contract.item_stacks.find(i => i.type === item.type);
    const cost = item.quantity * item.price_per
    totalCost += cost

    return <tr key={idx}>
        <td>{itemStack.type > 0 ? <Image
          src={`https://images.evetech.net/types/${itemStack.type}/icon?size=64`}
        ></Image> : null}</td>
      <td>{itemStack.name}</td>
      <td>{itemStack.quantity}</td>
      <td>{item.quantity}</td>
      <td>{item.price_per}</td>
      <td>{new Intl.NumberFormat('en-US').format(cost)} ISK</td>
    </tr>
  })

  return (
    <>
      <Row>
        <Col>
          <h1>Bid for {bid.contract.title}</h1>
          <p className="lead">
            Bid Status: <BidStatus status={bid.status} /> <br />
            Total Cost: <b>{new Intl.NumberFormat('en-US').format(totalCost)} ISK</b><br />
            Delivery Date: {new Intl.DateTimeFormat("default", { timeZone: "UTC" }).format(
              new Date(bid.contract.delivery_date)
            )}
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <td></td>
                <td>Name</td>
                <td>Quantity Needed</td>
                <td>Amount</td>
                <td>Price Per Item</td>
                <td>Stack Cost</td>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>

        </Col>
      </Row>
    </>
  )
}