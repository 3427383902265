import { Table, Image } from "react-bootstrap";
import { ItemStack } from "../types";

interface Props {
  items: ItemStack[]
}

export default function BidItems({items}: Props) {
  const rows = items.map((item, idx) => {
    const cost = item.quantity * item.price_per

    return <tr key={idx}>
        <td>{item.type > 0 ? <Image
          src={`https://images.evetech.net/types/${item.type}/icon?size=64`}
        ></Image> : null}</td>
      <td>{item.name}</td>
      <td>{item.quantity}</td>
      <td>{new Intl.NumberFormat('en-US').format(item.price_per)} ISK</td>
      <td>{new Intl.NumberFormat('en-US').format(cost)} ISK</td>
    </tr>
  })

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <td></td>
            <td>Name</td>
            <td>Bid Amount</td>
            <td>Per Price</td>
            <td>Total Cost</td>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </>
  )
}