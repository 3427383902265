import { Badge, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Contract } from "../types";

interface Props {
  contracts: Contract[]
}

export default function AdminContractsTable({contracts}: Props) {
  const rows = contracts.map((c, i) => {
    return (
      <tr key={i}>
        <td>{c.title}</td>
        <td>
          <h4>
            <Badge>{c.restriction_type}</Badge>
          </h4>
        </td>
        <td>{c.bids ? c.bids.length : 0}</td>
        <td>
          {new Intl.DateTimeFormat("default", { timeZone: "UTC" }).format(
            new Date(c.bid_deadline)
          )}
        </td>
        <td>
          {new Intl.DateTimeFormat("default", { timeZone: "UTC" }).format(
            new Date(c.delivery_date)
          )}
        </td>
        <td>
          <Link to={`/admin/contracts/${c.id}`} className="btn btn-info">
            View
          </Link>
        </td>
      </tr>
    );
  });

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <td>Title</td>
          <td>Type</td>
          <td>Bid Count</td>
          <td>Bid Deadline</td>
          <td>Delivery Date</td>
          <td></td>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  )
}