import { Card, Button, ButtonGroup, ListGroup } from "react-bootstrap";
import ItemList from "./ContractItemList";
import { BidStack, Contract as ContractType, Fulfillment } from '../types'
import BidModal from "./BidModal";
import { useEffect, useState } from "react";
import BidStatus from "./BidStatus";
import { canBidBeEdited, doFetch, getContractStatus, isContractPastBidDeadline } from "../utils";

interface ContractProps {
  contract: ContractType
  showActions: boolean
  pullFulfillments: boolean
}

export default function Contract({ contract, showActions, pullFulfillments }: ContractProps) {
  const [show, setShow] = useState(false)
  const [fulfillment, setFulfillment] = useState<Fulfillment[]>([])

  const bid = contract.bids ? contract.bids[0] : null

  useEffect(() => {
    if (!pullFulfillments) {
      return
    }

    doFetch(`/api/admin/contracts/${contract.id}/fulfillment`)
    .then(resp => {
      if (!resp.ok) {
        throw new Error('invalid response')
      }

      return resp.json()
    })
    .then(data => {
      setFulfillment(data)
    })
  }, [contract.id, pullFulfillments])

  const onClose = (save: boolean, items: BidStack[]) => {
    setShow(false)
    if (!save) {
      return
    }
    items = items.filter(i => i.quantity > 0);

    if (items.length === 0) {
      // lets just delete the bid
      removeBid()
      return
    }

    let url = `/api/contracts/${contract.id}/bids`
    if (bid) {
      url = `/api/contracts/${contract.id}/bids/${bid.id}`
    }

    doFetch(url, {
      method: bid ? 'PATCH' : 'POST',
      body: JSON.stringify({
        items: items
      })
    }).then(resp => {
      if (!resp.ok) {
        throw new Error('invalid status code ' + resp.statusText)
      }
      return resp.json()
    }).then(_ => {
      // TODO: Replace this with redux or something
      window.location.reload()
    }).catch(err => {
      console.error(err)
    })
  }

  const removeBid = () => {
    if (!window.confirm("Are you sure?")) {
      return
    }

    if (!bid) {
      return
    }

    doFetch(`/api/contracts/${contract.id}/bids/${bid.id}`, {
      method: 'DELETE',
    }).then(resp => {
      if (!resp.ok) {
        throw new Error('invalid status code ' + resp.statusText)
      }
      window.location.reload()
    }).catch(err => {
      console.error(err)
    })
  }

  let bidSection;
  if (showActions) {
    let actions = []
    if (!isContractPastBidDeadline(contract)) {
      let bid = contract.bids && contract.bids.length > 0 ? contract.bids[0] : undefined
      if (bid) {
        if (canBidBeEdited(bid, contract)) {
          actions.push(<Button key={0} variant="warning" onClick={() => setShow(true)}>Edit Bid</Button>)
          actions.push(<Button key={1} variant="danger" onClick={removeBid}>Delete Bid</Button>)
        }
      } else {
        actions.push(<Button key={0} variant="primary" onClick={() => setShow(true)}>Submit Bid</Button>)
      }
    }

    bidSection = (
      <ListGroup className="list-group-flush">
        <ListGroup.Item>
          <Card.Title>Bid Details</Card.Title>
          <Card.Text>
            <>
              <b>Bid Status:</b> <BidStatus status={!!bid ? bid.status : ''} /><br />
            </>
          </Card.Text>
        </ListGroup.Item>
        <ListGroup.Item>
          <ButtonGroup className="mb-2">
            {actions}
          </ButtonGroup>
        </ListGroup.Item>
      </ListGroup>
    )
  }

  return (
    <Card className="card-bottom">
      <Card.Header as="h3">{contract.title}</Card.Header>
      {bidSection ? bidSection : null }
      <Card.Body>
        <Card.Title>Items Needed</Card.Title>
        <ItemList items={contract.item_stacks} fulfillment={fulfillment} status={getContractStatus(contract)} />
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroup.Item>
          <b>Bid Deadline</b>: {new Intl.DateTimeFormat("default", { timeZone: "UTC" }).format(new Date(contract.bid_deadline))}
        </ListGroup.Item>
        <ListGroup.Item>
          <b>Delivery Deadline</b> {new Intl.DateTimeFormat("default", { timeZone: "UTC" }).format(new Date(contract.delivery_date))}
        </ListGroup.Item>
      </ListGroup>

      <BidModal contract={contract} bid={bid} onClose={onClose} show={show} />
    </Card>
  );
}
