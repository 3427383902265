import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { doFetch } from "../utils";
import Contract from "./Contract";

export default function Contracts() {
  const [contracts, setContracts] = useState([]);

  useEffect(() => {
    doFetch("/api/contracts")
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }
      })
      .then((data) => {
        setContracts(data.contracts);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const cards = contracts.map((c, i) => {
    return (
      <Col xs={12} md={4} key={i}>
        <Contract showActions contract={c} pullFulfillments={false} />
      </Col>
    );
  });

  if (cards.length === 0) {
    cards.push(
      <p className="lead" key={0}>
        No contracts available at this time
      </p>
    )
  }

  return (
    <>
      <Row>
        <Col>
          <h1>Available Contracts</h1>
        </Col>
      </Row>
      <Row>{cards}</Row>
    </>
  );
}
