import { Navigate } from "react-router-dom";
import Contracts from "../components/Contracts";
import { useAppSelector } from "../redux/hooks";
import { isLoggedIn } from "../redux/user";

export default function Home() {
  const loggedIn = useAppSelector(isLoggedIn)

  if (!loggedIn) {
    return <Navigate to="/login" />;
  }

  return <Contracts />;
}
