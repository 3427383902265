import { useEffect, useState } from "react";
import { Image, Form, InputGroup, ListGroup } from "react-bootstrap";
import { SDEType } from "../types";
import { doFetch } from '../utils';

interface Props {
  stackID: number
  type: SDEType
  onSelect: (id: number, t: SDEType) => void;
}


export default function SDEAutocomplete(props: Props) {
  const [valid, setValid] = useState(!!props.type.id)
  const [input, setInput] = useState(props.type.name)
  const [suggestions, setSuggestions] = useState<boolean>(false)
  const [options, setOptions] = useState<SDEType[]>([]);

  useEffect(() => {
    getOptions(input)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChange = (e) => {
    setInput(e.target.value)
    getOptions(e.target.value)
    setValid(false)
  }

  const select = (t: SDEType) => {
    setSuggestions(false)
    setInput(t.name)
    setValid(true)
    props.onSelect(props.stackID, t)
  }

  const getOptions = (name: string) => {
    doFetch(`/api/sde/search?name=${name}`).then((resp) => {
      if (!resp.ok) {
        throw new Error(`search returned ${resp.statusText}`)
      }

      return resp.json()
    }).then((data) => {
      setOptions(data.results)
    }).catch((err: Error) => {
      console.error(err);
    })
  }

  const optionItems = options.map((o, i) => {
    return (
      <ListGroup.Item className="autocomplete-item" key={i} onClick={() => select(o)}>
         <Image
          src={`https://images.evetech.net/types/${o.id}/icon?size=32`}
        ></Image>
        {o.name}
      </ListGroup.Item>
    )
  })

  return (
    <>
      <InputGroup>
        <Form.Control onFocus={() => setSuggestions(true)} onBlur={() => setTimeout(() => setSuggestions(false), 1000)} isInvalid={!valid} onChange={onChange} value={input} type="input" />
      </InputGroup>
      {suggestions ? <ListGroup className="autocomplete-items">
          {optionItems}
      </ListGroup> : null}
    </>
  )
}