import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAppSelector } from "../redux/hooks";
import { isLeader } from "../redux/user";

export default function NavHeader() {
  const leader = useAppSelector(isLeader)

  return (
    <Navbar variant="dark" bg="dark" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
          Procurement
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/bids">
              Your Bid History
            </Nav.Link>
            {leader ? 
            <NavDropdown title="Admin" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/admin/contracts">
                Contracts
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/admin/users">
                Users
              </NavDropdown.Item>
            </NavDropdown> : null}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
