import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ContractForm from "../components/ContractForm";
import { Contract, ContractInput } from "../types"
import { doFetch } from "../utils";

export default function NewContract() {
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState<boolean>(false);

  const onSubmit = (c: ContractInput) => {
    if (submitting) {
      return
    }

    setSubmitting(true);
    doFetch("/api/contracts", {
      method: "POST",
      body: JSON.stringify(c)
    }).then((resp: Response) => {
      setSubmitting(false)

      if (!resp.ok) {
        // TODO: flash error here

        throw new Error('invalid response')
      }

      return resp.json()
    }).then((data: Contract) => {
      if (!data) {
        throw new Error('contract was not returned')
      }


      navigate(`/admin/contracts/${data.id}`)
    }).catch((err: any) => {
      console.log(err)
    });
  }

  return (
    <>
      <Row>
        <h1>New Contract</h1>
      </Row>
      <Row>
        <Col xs={12} md={8}>
          <ContractForm onSubmit={onSubmit} />
        </Col>
      </Row>
    </>
  )
}
