import { Badge } from "react-bootstrap";

interface Props {
  status: string
}

export default function BidStatus({status}: Props) {
  const getColor = () => {
    switch (status) {
      case 'placed':
        return 'info'
      case 'accepted':
        return 'primary'
      case 'rejected':
        return 'danger'
      case 'defaulted':
        return 'warning'
      case 'completed':
        return 'success'
      default:
        return 'secondary'
    }
  }

  return (
    <Badge bg={getColor()}>{status || 'Open'}</Badge>
  )
}