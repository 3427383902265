import clone from "just-clone";
import { useEffect, useState } from "react";
import { Button, Modal, Table, Image, Form } from "react-bootstrap";
import { Bid, BidStack, Contract, ItemStack } from "../types";

interface Props {
  contract: Contract
  bid?: Bid
  show: boolean
  onClose: (save: boolean, items: BidStack[]) => void
}

export default function AdminEditContractItems(props: Props) {
  const [items, setItems] = useState<BidStack[]>([])

  useEffect(() => {
    if(props.show) {
      const pairs: BidStack[] = props.contract.item_stacks.map((item: ItemStack, idx: number) => {
        let quantity = 0;
        let price_per = 0;
        if (props.bid && props.bid.item_stacks) {
          const matchingItem = props.bid.item_stacks.find(i => i.type === item.type)
          if (matchingItem) {
            quantity = matchingItem.quantity;
            price_per = matchingItem.price_per;
          }
        }
        return {
          idRef: item.id,
          quantity: quantity,
          type: item.type,
          price_per: price_per
        }
      });

      // Set them each time the modal opens
      setItems(pairs)
    }
  }, [props.show, props.contract.item_stacks, props.bid])

  const changeItemQuantity = (e, idx: number) => {
    if (idx > -1 && idx < items.length) {
      const itemStack = props.contract.item_stacks.find(i => i.id === items[idx].idRef);

      let desiredQuantity = 0
      if (e.target.value) {
        desiredQuantity = parseInt(e.target.value)
      }

      if (desiredQuantity > itemStack.quantity || desiredQuantity < 0) {
        return
      }

      items[idx].quantity = desiredQuantity
      setItems(clone(items))
    }
  }

  const changeItemPricePer = (e, idx: number) => {
    if (idx > -1 && idx < items.length) {
      let desiredQuantity = 0
      if (e.target.value) {
        desiredQuantity = parseInt(e.target.value)
      }

      if (desiredQuantity < 0) {
        return
      }

      items[idx].price_per = desiredQuantity
      setItems(clone(items))
    }
  }

  let totalCost = 0
  const rows = items.map((item, idx) => {
    const itemStack = props.contract.item_stacks.find(i => i.id === item.idRef);
    const cost = item.quantity * item.price_per
    totalCost += cost

    return <tr key={idx}>
        <td>{itemStack.type > 0 ? <Image
          src={`https://images.evetech.net/types/${itemStack.type}/icon?size=64`}
        ></Image> : null}</td>
      <td>{itemStack.name}</td>
      <td>{itemStack.quantity}</td>
      <td><Form.Control type="number" isInvalid={item.price_per > 0 && item.quantity <= 0} value={item.quantity} onChange={(e) => changeItemQuantity(e, idx)} /></td>
      <td><Form.Control type="number" isInvalid={item.quantity > 0 && item.price_per <= 0} step={1000} lang="en" value={item.price_per} onChange={(e) => changeItemPricePer(e, idx)} /></td>
      <td>{new Intl.NumberFormat('en-US').format(cost)} ISK</td>
    </tr>
  })

  return <>
    <Modal show={props.show} onHide={() => props.onClose(false, items)} backdrop="static" size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Bid for {props.contract.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <td></td>
              <td>Name</td>
              <td>Quantity Needed</td>
              <td>Amount</td>
              <td>Price Per Item</td>
              <td>Stack Cost</td>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
          <p className="float-end">
            Total Bid Amount: {new Intl.NumberFormat('en-US').format(totalCost)} ISK
          </p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onClose(false, items)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => props.onClose(true, items)}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  </>
}