import { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import BidsTable from "../components/BidsTable";
import BidStatus from "../components/BidStatus";
import { Bid, BidTableActionProps } from "../types";
import { doFetch } from "../utils";

const BidActions = ({bid}: BidTableActionProps) => {
  return (
    <Link to={`/bids/${bid.id}`}>
      <Button variant="primary">View</Button>
    </Link>
  )
}

export default function Bids() {
  const [bids, setBids] = useState<Bid[]>([])

  useEffect(() => {
    doFetch(`/api/bids`)
    .then(resp => {
      if (!resp.ok) {
        throw new Error(`invalid response ${resp.statusText}`)
      }

      return resp.json()
    }).then((data: any) => {
      setBids(data.bids)
    }).catch(e => {
      console.error(e)
    })
  }, [])

  return (
    <>
      <Row>
        <Col>
          <h1>Your Bids</h1>
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <BidsTable bids={bids} actionComponent={BidActions} />
        </Col>
        <Col md={4}>
          <Card>
            <Card.Header as="h5">Bid Status Explanation</Card.Header>
            <Card.Body>
              <Card.Text>
                <BidStatus status="placed" /> means that you have placed your bid, but it has not been accepted or rejected for the contract.<br />
                <BidStatus status="accepted" /> means that your bid has been accepted for the contract. You will need to deliver the items by the contract deadline.<br />
                <BidStatus status="completed" /> means that procurement has marked your contracct has delivered.<br />
                <BidStatus status="rejected" /> means that procurement has not accepted your bid for the contract.<br />
                <BidStatus status="defaulted" /> means that you did not deliver the items to procurement by the contract deadline.<br />
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
