import { Accordion, ListGroup } from "react-bootstrap";
import ItemStack from "./ContractItemStack";
import { Fulfillment, ItemStack as ItemStackType } from '../types'

interface ItemListProps {
  items: ItemStackType[]
  fulfillment?: Fulfillment[]
  status: 'accepted' | 'completed'
}

export default function ContractItemList({ items, fulfillment, status }: ItemListProps) {
  if (items.length === 0) {
    return <>No Items</>
  }

  const getFulfillmentForStack = (stack: ItemStackType) => {
    if (!fulfillment) {
      return undefined
    }
    return fulfillment.filter(f => f.type === stack.type)
  }

  const elements = items.map((item, i) => {
    return <ItemStack key={i} stack={item} fulfillment={getFulfillmentForStack(item)} />;
  });
  const exportText = items.reduce((acc: string, item) => {
    acc += `${item.name}\t${item.quantity}\t\n`;
    return acc;
  }, "");

  return (
    <>
      <ListGroup>
        {elements}
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Export Text</Accordion.Header>
            <Accordion.Body>
              <pre>{exportText}</pre>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </ListGroup>
    </>
  );
}
