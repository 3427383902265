import { useState, useEffect } from "react";
import { Row, Col, Card, ButtonGroup, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import AdminContractsTable from "../components/AdminContractsTable";
import { Contract } from "../types";
import { doFetch } from "../utils";

export default function AdminContracts() {
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [pastContracts, setPastContracts] =useState<Contract[]>([]);

  useEffect(() => {
    doFetch("/api/admin/contracts")
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }
      })
      .then((data) => {
        setContracts(data.contracts);
        setPastContracts(data.past_contracts);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return (
    <>
      <Row>
        <h1>Contract Admin</h1>
      </Row>
      <Row>
        <Col md={8}>
          <Tabs defaultActiveKey="upcoming" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="upcoming" title="Upcoming">
              <AdminContractsTable contracts={contracts} />
            </Tab>
            <Tab eventKey="past" title="Past Contracts">
              <AdminContractsTable contracts={pastContracts} />
            </Tab>
          </Tabs>

        </Col>
        <Col md={4}>
          <Card>
            <Card.Header>Actions</Card.Header>
            <Card.Body>
              <ButtonGroup>
                <Link to="/admin/contracts/new" className="btn btn-primary">
                  New Contract
                </Link>
              </ButtonGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
