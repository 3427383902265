import { useEffect, useState } from "react";
import { Pagination, Row } from "react-bootstrap";
import UsersTable from "../components/UsersTable";
import { User } from "../types";
import { doFetch } from "../utils";

export default function Users() {
  const [users, setUsers] = useState<User[]>([]);
  const [count, setCount] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    doFetch(`/api/admin/users?page=${page}`)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }
      })
      .then((data) => {
        setUsers(data.results)
        setPerPage(data.per_page)
        setCount(data.count)
      })
      .catch((e) => {
        console.error(e);
      });
  }, [page]);

  return (
    <>
      <Row>
        <h1>Users</h1>
      </Row>
      <Row>
        <UsersTable users={users} />
      </Row>
      <Row>
        <Pagination>
          <Pagination.Prev disabled={page === 1} onClick={() => setPage(page-1)} />
          <Pagination.Next disabled={(count / perPage) <= page} onClick={() => setPage(page+1)} />
        </Pagination>
      </Row>
    </>
  );
}
