import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Contract, ContractInput } from "../types";
import { addDaysToDate } from "../utils";

interface FormProps {
  onSubmit: (c: ContractInput) => void
  contract?: Contract
}

export default function ContractForm({contract, onSubmit}: FormProps) {
  const now = new Date();
  const [title, setTitle] = useState(contract ? contract.title : '');
  const [restriction, setRestriction] = useState(contract ? contract.restriction_type : '');
  const [deadline, setDeadline] = useState(contract ? new Date(contract.bid_deadline).toISOString().slice(0, 19) : addDaysToDate(now, 3).toISOString().slice(0, 19));
  const [delivery, setDelivery] = useState(contract ? new Date(contract.delivery_date).toISOString().slice(0, 19) : addDaysToDate(now, 7).toISOString().slice(0, 19));

  const submitForm = (e) => {
    e.preventDefault();

    onSubmit({
      title: title,
      type: restriction,
      bid_deadline: new Date(deadline).toISOString(),
      delivery_date: new Date(delivery).toISOString(),
    })
  };

  return (
    <Form onSubmit={submitForm}>
      <Form.Group className="mb-3">
        <Form.Label>Title</Form.Label>
        <Form.Control required type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Enter title" />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Contract Type</Form.Label>
        <Form.Select required value={restriction} onChange={(e) => setRestriction(e.target.value)}>
          <option key={1}>Open this select menu</option>
          <option key={2} value="closed">Closed</option>
          <option key={3} value="restricted">Restricted</option>
          <option key={4} value="open">Open</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Bid Deadline (UTC)</Form.Label>
        <Form.Control
          required
          type="datetime-local"
          placeholder="Enter title"
          value={deadline} onChange={(e) => setDeadline(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Delivery (UTC)</Form.Label>
        <Form.Control
          required
          type="datetime-local"
          placeholder="Enter title"
          value={delivery} onChange={(e) => setDelivery(e.target.value)}
        />
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
}