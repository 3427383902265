import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Contract from "../components/Contract";
import { doFetch } from "../utils";

export default function ViewContract() {
  const { id } = useParams();
  const [contract, setContract] = useState(null);

  useEffect(() => {
    doFetch(`/api/contracts/${id}`)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }
      })
      .then((data) => {
        setContract(data);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [id]);

  if (!contract) {
    return <h1>Loading...</h1>;
  }

  return (
    <>
      <Row>
        <h1>Contract</h1>
      </Row>
      <Row>
        <Col md={6}>
          <Contract showActions contract={contract} pullFulfillments={false} />
        </Col>
      </Row>
    </>
  );
}
