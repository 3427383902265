//@ts-ignore
import Cookies from "js-cookie";
import * as Sentry from "@sentry/react";
import { Bid, Contract, User } from "./types";

export function hasSession(): boolean {
  const loginToken = Cookies.get("session");
  return !!loginToken;
}

export function logout() {
  Cookies.remove("session");
}

export function doFetch(
  input: RequestInfo,
  init?: RequestInit
): Promise<Response> {
  return fetch(input, init).then((resp) => {
    let [first, second] = resp.body!.tee();

    if (!resp.ok) {
      // Submit all 5xx to sentry
      if (resp.status <= 500) {
        const tapped = new Response(first);
        tapped.text().then((data) => {
          Sentry.withScope(function (scope) {
            scope.setTag("response-text", data);
            scope.setTag("response-status", resp.status);
            Sentry.captureException(new Error(`invalid response`));
          });
        });
      }

      // logged out
      if (resp.status === 401) {
        document.location.replace("/login");
      }
    }

    // No content
    if (resp.status === 204) {
      second = null;
    }

    return new Response(second, {
      headers: resp.headers,
      status: resp.status,
      statusText: resp.statusText,
    });
  });
}

export const DateTimeDisplayOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  hour12: false,
  timeZone: "UTC",
};

export function formatTime(timeStr: string): string {
  return new Intl.DateTimeFormat("default", DateTimeDisplayOptions).format(
    new Date(timeStr)
  );
}

export function formatCurrency(value: number): string {
  return `${new Intl.NumberFormat("en-US").format(value)} ISK`;
}

export function clearRole() {
  localStorage.removeItem("user_role");
}

export function getRole(): string | null {
  return localStorage.getItem("user_role");
}

export function setRole(role: string) {
  localStorage.setItem("user_role", role);
}

// TODO: move these to some models folder
export function isContractPastBidDeadline(contract: Contract): boolean {
  const now = new Date();
  if (now > new Date(contract.bid_deadline)) {
    return true;
  }

  return false;
}

export function getContractStatus(
  contract: Contract
): "accepted" | "completed" {
  const now = new Date();
  if (now >= new Date(contract.delivery_date)) {
    return "completed";
  }

  if (now > new Date(contract.bid_deadline)) {
    return "accepted";
  }

  return "accepted";
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getRoleForUser(user: User): string {
  switch(user.role) {
    case 2:
      return "Sig Leader"
    case 1:
      return "Sig Member"
    default:
      return "Line"
  }
}

export function canBidBeEdited(bid: Bid, contract?: Contract) {
  if (bid.contract && isContractPastBidDeadline(contract || bid.contract)) {
    return false
  }
  return bid.status === "placed" || bid.status === "rejected"
}

export function addDaysToDate(date: Date, days: number): Date {
  var copied = new Date(date.valueOf());
  copied.setDate(copied.getDate() + days);
  copied.setUTCHours(0);
  copied.setUTCMinutes(0);
  copied.setUTCSeconds(0);
  copied.setUTCMilliseconds(0);
  return copied;
}
