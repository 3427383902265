import React, { FunctionComponent } from "react";
import {Accordion, Table } from "react-bootstrap";
import { Bid, BidTableActionProps } from "../types";
import { formatCurrency, formatTime } from "../utils";
import BidItems from "./BidItems";
import BidStatus from "./BidStatus";

interface Props {
  bids: Bid[]
  showUser?: boolean
  actionComponent: FunctionComponent<BidTableActionProps>
}

export default function BidsTable({bids, showUser, actionComponent}: Props) {
  let actualBids = bids ? bids : []
  const rows = actualBids.map((b, idx) => {
    let cost = 0;
    if (b.item_stacks && b.item_stacks.length > 0) {
      cost = b.item_stacks.reduce((acc, i) => {
        return acc + (i.price_per * i.quantity)
      }, 0)
    }

    return (
      <React.Fragment key={idx}>
        <tr>
          <td>
            {showUser ? b.user.username : b.contract.title}
          </td>
          <td>{formatCurrency(cost)}</td>
          <td><BidStatus status={b.status} /></td>
          <td>{formatTime(b.contract.delivery_date)}</td>
          <td>
            {React.createElement(actionComponent, {bid: b})}
          </td>
        </tr>
        <tr>
          <td colSpan={5}>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Items</Accordion.Header>
                <Accordion.Body className="bid-items-list">
                  <BidItems items={b.item_stacks} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </td>
        </tr>
      </React.Fragment>
    )
  })

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <td>{showUser ? 'User' : 'Contract'}</td>
          <td>Total Cost</td>
          <td>Status</td>
          <td>Delivery Date</td>
          <td>Actions</td>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  )
}