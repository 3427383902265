import { ListGroup, Image, ProgressBar } from "react-bootstrap";
import { Fulfillment, ItemStack as ItemStackType } from "../types";
import { capitalizeFirstLetter } from "../utils";

interface ItemStackProps {
  stack: ItemStackType
  fulfillment: Fulfillment[]
}

export default function ContractItemStack({ stack, fulfillment }: ItemStackProps) {
  const { type, name, quantity } = stack;
  const renderType = "icon";

  let fulfullmentText = ""
  let bars = []
  if (fulfillment) {
    bars = fulfillment.map((f, idx) => {
      fulfullmentText += `${capitalizeFirstLetter(f.status)}: ${f.amount}\n`

      return <ProgressBar variant={f.status === "accepted" ? "primary" : "success"} now={f.amount} key={idx} />
    })
  }

  return (
    <ListGroup.Item>
      <div>
        <Image
          src={`https://images.evetech.net/types/${type}/${renderType}?size=64`}
        ></Image>
        <h4 className="icon-text">{name}</h4>
      </div>
      <h5>Need: {new Intl.NumberFormat('en-US').format(quantity)}</h5>
      {bars.length > 0 ? 
        <>
          <p className="lead">
            {fulfullmentText}
          </p>
          <ProgressBar>
            {bars}
          </ProgressBar>
        </>
      : null}
    </ListGroup.Item>
  );
}
