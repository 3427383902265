import clone from "just-clone";
import { useEffect, useState } from "react";
import { Button, Modal, Table, Image, Form } from "react-bootstrap";
import SDEAutocomplete from "./SDEAutocomplete";
import { Contract, EditableItemStack, ItemStack, SDEType } from "../types";

interface Props {
  contract: Contract
  show: boolean
  onClose: (save: boolean, items: EditableItemStack[]) => void
}

export default function AdminEditContractItems(props: Props) {
  // clone a copy to edit in the form
  const [items, setItems] = useState<EditableItemStack[]>(clone(props.contract.item_stacks))
  useEffect(() => {
    if(props.show) {
      // Set them each time the modal opens
      setItems(clone(props.contract.item_stacks))
    }
  }, [props.show, props.contract.item_stacks])

  const addItemStack = () => {
    items.push({
      name: '',
      type: 0,
      quantity: 1,
    })
    setItems(clone(items))
  }

  const removeItemStack = (idx: number) => {
    if (idx > -1 && idx < items.length) {
      items.splice(idx, 1)
      setItems(clone(items))
    }
  }

  const changeItemQuantity = (e, idx: number) => {
    if (idx > -1 && idx < items.length) {
      if (!e.target.value) {
        items[idx].quantity = 0
      } else {
        items[idx].quantity = parseInt(e.target.value)
        if (!items[idx].quantity) {
          items[idx].quantity = 0
        }
      }
      setItems(clone(items))
    }
  }

  const setItemType = (idx: number, t: SDEType) => {
    const existingType = items.findIndex((i) => i.type === t.id);
    if (existingType) {
      // flash error, but reject this
    }

    if (idx > -1 && idx < items.length) {
      items[idx].type = t.id
      items[idx].name = t.name
      setItems(clone(items))
    }
  }

  const rows = items.map((item: ItemStack, idx: number) => {
    return <tr key={idx}>
        <td>{item.type > 0 ? <Image
          src={`https://images.evetech.net/types/${item.type}/icon?size=64`}
        ></Image> : null}</td>
      <td><SDEAutocomplete type={{ name: item.name, id: item.type }} onSelect={setItemType} stackID={idx} /></td>
      <td><Form.Control type="number" value={item.quantity} onChange={(e) => changeItemQuantity(e, idx)} /></td>
      <td>
        <Button variant="danger" onClick={() => removeItemStack(idx)}>Remove</Button>
      </td>
    </tr>
  })

  return <>
    <Modal show={props.show} onHide={() => props.onClose(false, items)} backdrop="static" size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Edit Contract Items</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Button onClick={addItemStack}>Add Item</Button>
        <Table striped bordered hover>
          <thead>
            <tr>
              <td></td>
              <td>Name</td>
              <td>Quantity</td>
              <td></td>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onClose(false, items)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => props.onClose(true, items)}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  </>
}